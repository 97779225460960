import axios from "axios";
import isEmpty from "is-empty";
import backurl from "../lib/config"
import {
    GET_ERRORS,
    IP_ADD,
    IP_UPDATE
} from "./types";

export const addIpaddress = (param, history) => dispatch => {
    var ipData = new FormData();
   
    ipData.append('whitelist',param.whitelist);
    

//console.log("formdata>>>>>>>",formData)   
    axios
        .post(backurl+"/api/ip-add", ipData)
        .then(res =>{   
           
            dispatch({
                type: IP_ADD,
                payload: res,
            })
        }
        ).catch(err =>{   
           
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
    }
    );
};

export const updateIP = (param) => dispatch => {
    var formUpdateData = new FormData();
    console.log("Description1",param);
    if (!isEmpty(param._id))   formUpdateData.append('_id',param._id );
   
    if (!isEmpty(param.Title)) formUpdateData.append('Title',param.Title );
    if (!isEmpty(param.Heading)) formUpdateData.append('Heading',param.Heading );
    if (!isEmpty(param.Heading1)) formUpdateData.append('Heading1',param.Heading1 );
    if (!isEmpty(param.Heading2)) formUpdateData.append('Heading2',param.Heading2 );
    if (!isEmpty(param.Heading3)) formUpdateData.append('Heading3',param.Heading3 );
    if (!isEmpty(param.Description))formUpdateData.append('Description',param.Description );
    if (!isEmpty(param?.Description1))formUpdateData.append('Description1',param?.Description1 );
    if (!isEmpty(param?.Description2))formUpdateData.append('Description2',param?.Description2 );
    if (!isEmpty(param?.Description3))formUpdateData.append('Description3',param.Description3 );
    
    if (!isEmpty(param.file)) {
        formUpdateData.append('file',param.file );
       
    }
    if (!isEmpty(param.file3)) {
        formUpdateData.append('file3',param.file3 );
       
    }
   
    if (!isEmpty(param.file1)) {
        formUpdateData.append('file1',param.file1 );
       
    }
   
    if (!isEmpty(param.file2)) {
        formUpdateData.append('file2',param.file2 );
       
    }
   
   
    axios
        .post(backurl+"/api/update/featuredes", formUpdateData)
        .then(res =>
            dispatch({
                type: IP_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const updatenews = (param) => dispatch => {
    var formUpdateData = new FormData();
    if (!isEmpty(param._id))   formUpdateData.append('_id',param._id );
   
   
    if (!isEmpty(param.Heading)) formUpdateData.append('Heading',param.Heading );
    if (!isEmpty(param.Description))formUpdateData.append('Description',param.Description );
    
    if (!isEmpty(param.file)) {
        formUpdateData.append('file',param.file );
       
    }
   
    axios
        .post(backurl+"/api/update/news", formUpdateData)
        .then(res =>
            dispatch({
                type: IP_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};