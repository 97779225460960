import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateIP } from "../../actions/ipAction";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class IpUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record.id,
            Title: this.props.record.Title,
            Heading: this.props.record.Heading,
            Heading1: this.props.record.Heading1,
            Heading2: this.props.record.Heading2,
            Heading3: this.props.record.Heading3,
            Description: this.props.record.Description,
            Description1: this.props.record.Description1,
            Description2: this.props.record.Description2,
            Description3: this.props.record.Description3,
            errors: {},
            file: {},
            file1: {},
            file2: {},
            file3: {},
        }
    }
    componentDidMount(props) {
        console.log("data :", this.props.record)
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                Title: nextProps.record.Title,
                Heading: nextProps.record.Heading,
                Heading1: nextProps.record.Heading1,
                Heading2: nextProps.record.Heading2,
                Heading3: nextProps.record.Heading3,
                Description: nextProps.record.Description,
                Description1: nextProps.record.Description1,
                Description2: nextProps.record.Description2,
                Description3: nextProps.record.Description3,
                file: nextProps.record.file,
                file1: nextProps.record.file1,
                file2: nextProps.record.file2,
                file3: nextProps.record.file3,
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined
            && nextProps.auth.form.data.success) {

            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        $('#update-ipaddress-modal').modal('hide');

    }

    onFileChange = event => {
        var name = event.target.value;

        this.setState({ file: event.target.files[0] });
        this.setState({ imagepath: name });

    };
    onFile1Change = event => {
        var name = event.target.value;

        this.setState({ file1: event.target.files[0] });
        

    };
    onFile22Change = event => {
        var name = event.target.value;

        this.setState({ file2: event.target.files[0] });
       

    };
    onFile3Change = event => {
        var name = event.target.value;

        this.setState({ file3: event.target.files[0] });
        
    };
    onChange = e => {

        if (e.target.id === 'Heading') {
            this.setState({ Heading: e.target.value });
        }
        if (e.target.id === 'Title') {
            this.setState({ Title: e.target.value });
        }
        if (e.target.id === 'Heading1') {
            this.setState({ Heading1: e.target.value });
        }
        if (e.target.id === 'Heading2') {
            this.setState({ Heading2: e.target.value });
        }
        if (e.target.id === 'Heading3') {
            this.setState({ Heading3: e.target.value });
        }
        if (e.target.id === 'Description') {
            this.setState({ Description: e.target.value });
        }
        if (e.target.id === 'Description1') {
            this.setState({ Description1: e.target.value });
        }
        if (e.target.id === 'Description2') {
            this.setState({ Description2: e.target.value });
        }
        if (e.target.id === 'Description3') {
            this.setState({ Description3: e.target.value });
        }
    };

    onIPUpdate = e => {
        e.preventDefault();
        const updateIp = {
            _id: this.state.id,
            Title : this.state.Title,
            Heading: this.state.Heading,
            Description: this.state.Description,
            Heading1: this.state.Heading1,
            Description1: this.state.Description1,
            Heading2: this.state.Heading2,
            Description2: this.state.Description2,
            Heading3: this.state.Heading3,
            Description3: this.state.Description3,
            file: this.state.file,
            file1:this.state.file1,
            file2:this.state.file2,
            file3:this.state.file3,
            
        };
        this.props.updateIP(updateIp);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-ipaddress-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Earnigs Steps </h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onIPUpdate} id="update-ip">
                                    <input
                                        onChange={(e) => this.onChange(e)}
                                        value={this.state.id}
                                        id="ip-update-id"
                                        type="text"
                                        className="d-none" />
                                         <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="whitelist">Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Title}
                                                id="Title"
                                                type="text"
                                                error={errors.Title}
                                                className={classnames("form-control", {
                                                    invalid: errors.Title
                                                })} />
                                            <span className="text-danger">{errors.Title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="whitelist">Heading</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Heading}
                                                id="Heading"
                                                type="text"
                                                error={errors.Heading}
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })} />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="whitelist">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.Description}
                                                id="Description"
                                                type="text"
                                                error={errors.Description}
                                                className={classnames("form-control", {
                                                    invalid: errors.Description
                                                })} />
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>
                                    </div>
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="logoURI">Update Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onFileChange}
                                                value={this.state.imagepath}
                                                error={errors.logoURI}
                                                id="logoURI"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.logoURI
                                                })}
                                            />
                                            <span className="text-danger">{errors.logoURI}</span>
                                        </div>
                                    </div> */}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="whitelist">Heading</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Heading1}
                                                id="Heading1"
                                                type="text"
                                                error={errors.Heading1}
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading1
                                                })} />
                                            <span className="text-danger">{errors.Heading1}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="whitelist">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.Description1}
                                                id="Description1"
                                                type="text"
                                                error={errors.Description1}
                                                className={classnames("form-control", {
                                                    invalid: errors.Description1
                                                })} />
                                            <span className="text-danger">{errors.Description1}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="logoURI">Update Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onFile1Change}
                                                value={this.state.imagepath}
                                                error={errors.logoURI}
                                                id="logoURI"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.logoURI
                                                })}
                                            />
                                            <span className="text-danger">{errors.logoURI}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="whitelist">Heading</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Heading2}
                                                id="Heading2"
                                                type="text"
                                                error={errors.Heading2}
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading2
                                                })} />
                                            <span className="text-danger">{errors.Heading2}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="whitelist">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.Description2}
                                                id="Description2"
                                                type="text"
                                                error={errors.Description2}
                                                className={classnames("form-control", {
                                                    invalid: errors.Description2
                                                })} />
                                            <span className="text-danger">{errors.Description2}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="logoURI">Update Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onFile22Change}
                                                value={this.state.imagepath}
                                                error={errors.logoURI}
                                                id="logoURI"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.logoURI
                                                })}
                                            />
                                            <span className="text-danger">{errors.logoURI}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="whitelist">Heading</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Heading3}
                                                id="Heading3"
                                                type="text"
                                                error={errors.Heading3}
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading3
                                                })} />
                                            <span className="text-danger">{errors.Heading3}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="whitelist">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.Description3}
                                                id="Description3"
                                                type="text"
                                                error={errors.Description3}
                                                className={classnames("form-control", {
                                                    invalid: errors.Description3
                                                })} />
                                            <span className="text-danger">{errors.Description3}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="logoURI">Update Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onFile3Change}
                                                value={this.state.imagepath}
                                                error={errors.logoURI}
                                                id="logoURI"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.logoURI
                                                })}
                                            />
                                            <span className="text-danger">{errors.logoURI}</span>
                                        </div>
                                    </div>
                                    
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-ip"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

IpUpdateModal.propTypes = {
    updateIP: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
    
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateIP }
)(withRouter(IpUpdateModal));
